<template>
  <div class="section-category d-flex align-center" :class="{'section-category--selected': selected}" v-on="$listeners">
    <v-img v-if="image" class="d-inline-block section-category__image" :src="image" height="24" width="24"/>
    <v-icon v-else class="d-inline-block section-category__icon">{{ $vuetify.icons.values.categorySection }}</v-icon>
    <p class="d-inline-block section-category__name">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'SectionCategoryItem',
  props: {
    text: String,
    image: {
      default: null,
      type: String
    },
    selected: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
.section-category {
  margin: 0 20px 15px 0;
  padding: 23px 20px;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  transition: all .3s ease-in-out;

  &__image {
    margin-right: 14px;
  }
  &__icon {
    margin-right: 14px;
    color: #000;
  }
  &__name {
    margin:0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
  }
  &--selected {
    background: #1FC1D0;
    border: 1px solid #D4D4D4;
    color: white;

    & .section-category__icon {
    color: white;

    }
  }
}
</style>
