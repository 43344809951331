<template>
  <v-row>
    <v-dialog
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card
        class="d-flex flex-column justify-center"
        height="449"
      >
        <v-img
          src="@/assets/img/register/successful.svg"
          width="103"
          max-height="103"
          class="mx-auto mb-10"
        ></v-img>
        <p class="text mx-10">¡Tu inscripción ha sido recibida!</p>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'ModalSuccess'
}
</script>

<style lang="scss" scoped>
.text {
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
  color: #343B4D;
}
</style>
