<template>
  <div
    class="d-flex"
    :class="{'flex-column mb-5': $vuetify.breakpoint.smAndDown}"
  >
    <!-- sideleft image -->
    <div
      class="main__sidebar d-flex flex-wrap flex-column justify-start align-start pb-15"
      :style="$vuetify.breakpoint.smAndDown ? '': 'flex-basis: 40%;'"
    >
      <v-img
        :src="$vuetify.breakpoint.smAndDown ? require('@/assets/img/register/right-frame-mobile.svg'): require('@/assets/img/register/left-frame.svg')"
        min-width="30%"
        :max-height="$vuetify.breakpoint.smAndDown ? '120vw': '20vw'"
        class="container-frame d-md-flex pa-0 ma-0"
        style="position: absolute; top: 0; "
        :style="$vuetify.breakpoint.smAndDown ? 'right: 0': 'left: 0;'"
      >
      </v-img>
      <div class="d-flex mt-10 pr-10" :class="{'flex-column': $vuetify.breakpoint.width<320}">
        <v-img
          :src="$vuetify.breakpoint.smAndDown ? require('@/assets/img/register/logo.svg'): require('@/assets/img/register/logo-dark.svg')"
          max-height="74"
          max-width="59"
          style="cursor: pointer; z-index: 2;"
          cover
          class="ml-10"
        ></v-img>
        <p class="header__name my-auto ml-5" :class="!$vuetify.breakpoint.smAndDown ? 'white-color': 'gradient-color'">USAMEDIC</p>
      </div>
      <p class="main__title mt-10 mx-10" v-if="$vuetify.breakpoint.smAndDown">
        Inscripciones <br>
        USAMEDIC
      </p>
      <p class="main__description mx-10 text-justify" v-if="$vuetify.breakpoint.smAndDown">
        {{ description }}
      </p>
      <v-img
        src="@/assets/img/register/doctor-ajolito.svg"
        max-width="283"
        max-height="283"
        class="mt-10 mx-auto"
      ></v-img>
      <p class="main__description mt-5 mb-auto mx-auto text-left" v-if="!$vuetify.breakpoint.smAndDown">
        {{ description }}
      </p>
    </div>
    <!-- sideleft image -->
    <!-- main register -->
    <div class="main__register mx-auto"
     :class="{'pl-15': !$vuetify.breakpoint.smAndDown}"
     :style="!$vuetify.breakpoint.smAndDown ? 'border-left: 1px solid #E6E6E6; overflow: hidden;': ''"
    >
      <p class="main__title mt-10" v-if="!$vuetify.breakpoint.smAndDown">
        Inscripciones <br>
        USAMEDIC
      </p>
      <v-form
        @submit.prevent="doRegister"
        class="mx-1"
        autocomplete="off"
        ref="registerForm"
      >
        <p class="form__question mb-3">¿En qué servicio estás interesado?</p>
        <SectionCategoryList/>
        <p class="form__question mb-2">¿A que curso deseas inscribirte?</p>
        <SectionList/>

        <p class="form__question my-5">Dirección de correo electrónico</p>
        <v-text-field
          v-model="register.email"
          @input="(val) => (register.email = register.email.toLowerCase())"
          solo
          data-test-id="email"
          autocomplete="off"
          class="field ma-0 mb-2"
          :rules="[rules.required, rules.email]"
          ref="email"
        ></v-text-field>
        <p class="form__question my-5">DNI/CARNET-EXT</p>
        <v-text-field
          v-model="register.dni"
          solo
          data-test-id="dni"
          class="field ma-0 mb-2"
          type="number"
          :rules="[rules.required, rules.dniCarnet]"
          ref="dni"
        ></v-text-field>
        <p class="form__question my-5">Adjunta una foto de tu DNI (Solo la parte frontal)</p>
        <the-upload-files
          :files="dniFoto"
          typeFor="dniFoto"
          @removeImg="removeNewDniPhoto"
        />
        <input
          type="file"
          data-test-id="dni-photo"
          multiple
          class="hide-input-file"
          accept=".png, .jpeg, .jpg, .webp"
          @change="onFileChangeDni"
          id="dniFoto"
          name="filesx"
          ref="dniFoto"
        >
        <p v-if="limit && dniFoto.length === 0" class="mt-1 ml-2" style="font-size: 11px; color: red;">
          Este campo es necesario
        </p>
        <p class="form__question my-5">Nombres</p>
        <v-text-field
          v-model="register.nombres"
          solo
          data-test-id="firstname"
          class="field ma-0 mb-2"
          :rules="[rules.required]"
          ref="nombres"
        ></v-text-field>
        <p class="form__question my-5">Apellidos</p>
        <v-text-field
          v-model="register.apellidos"
          solo
          data-test-id="lastname"
          class="field ma-0 mb-2"
          :rules="[rules.required]"
          ref="apellidos"
        ></v-text-field>
        <div>
          <p class="form__question my-5">Fecha de nacimiento</p>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            style="border: 1px solid #D5D5D5; border-radius: 10px !important;"
            class=""
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="register.fechaNacimiento"
                readonly
                data-test-id="birthday"
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"
                solo
                class="field mb-5"
                ref="fechaNacimiento"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="register.fechaNacimiento"
              @change="menu = false"
              class="datepicker"
              header-color="primary"
              max="2010-01-01"
            ></v-date-picker>
          </v-menu>
        </div>
        <p class="form__question my-5">Departamento donde vives</p>
        <v-autocomplete
          :items="departaments"
          item-value="id"
          item-text="name"
          v-model="register.region"
          solo
          data-test-id="departaments"
          class="field"
          :rules="[rules.required]"
          color="primary"
          ref="region"
        ></v-autocomplete>
        <p class="form__question my-5">Número de celular (No teléfono)</p>
        <v-text-field
          v-model="register.celular"
          solo
          data-test-id="cellphone"
          class="field ma-0 mb-2"
          :rules="[rules.required, rules.cellphone]"
          ref="celular"
          type="number"
          autocomplete="off"
        ></v-text-field>
        <!-- <base-button
          v-if="!continueRegister"
          class="my-10"
          :width="$vuetify.breakpoint.smAndDown ? '100%': '369'"
          @click="ToggleRegisterContinue"
          :loading="loading1"
        >Continuar</base-button> -->
        <p class="form__question my-5">Universidad de estudio</p>
        <v-autocomplete
          :items="instituciones"
          item-text="nombre"
          item-value="id"
          v-model="register.institucionId"
          solo
          data-test-id="institution"
          class="field"
          :rules="[rules.required]"
          color="primary"
          ref="intitucion"
        ></v-autocomplete>
        <p class="form__question my-5" :class="$vuetify.breakpoint.smAndDown ? 'mt-6': ''">Grado de formacion</p>
        <v-combobox
          v-model="register.gradosFormacion"
          :items="grados"
          item-text="nombre"
          item-value="id"
          chips
          clearable
          multiple
          solo
          class="field mb-5"
          :rules="[rules.required]"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="removeGrado(item)"
            >
              <strong>{{ item.nombre }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
        <!-- <v-autocomplete
          :items="grados"
          item-text="nombre"
          item-value="id"
          v-model="register.gradosFormacion"
          solo
          class="field mb-5"
          :rules="[rules.required]"
          color="primary"
          ref="grado"
        ></v-autocomplete> -->
        <p class="form__question my-5">Número de carnet CMP (Opcional)</p>
        <v-text-field
          v-model="register.carnet"
          :rules="[rules.maxLength(6)]"
          type="number"
          solo
          class="field ma-0 mb-6"
        ></v-text-field>
        <p class="form__question mb-5">¿Cómo te enteraste de Usamedic? *</p>
        <v-checkbox
          v-for="canal in references"
          :key="canal.id"
          v-model="register.medio"
          :label="canal.nombre"
          :value="canal.nombre"
          color="blue"
          class="pa-0 ma-0 check"
          ref="references"
        ></v-checkbox>
        <div class="d-flex">
          <v-checkbox
            v-model="otroMedioBool"
            :label="otroMedioBool ? 'Otro: ': 'Otro'"
            color="blue"
            class="pa-0 ma-0"
          ></v-checkbox>
          <v-slide-x-transition>
            <input
              v-if="otroMedioBool"
              v-model="register.otroMedio"
              type="text"
              class="another ml-2 mb-5 px-1"
            >
          </v-slide-x-transition>
        </div>
        <p v-if="limit && register.medio.length === 0 && register.otroMedio === ''" class="ml-2" style="font-size: 11px; color: red;">
          Este campo es necesario
        </p>
        <p class="form__question mb-5">Código promocional (Opcional)</p>
        <v-text-field
          v-model="register.promoCode"
          solo
          class="field ma-0 mb-6"
          ref="promoCode"
          type="text"
          @focus="evaluate = true"
        >
          <template v-slot:append v-if="evaluate && register.promoCode !== '' && register.promoCode !== null">
            <v-icon color="green" v-if="codeValid">mdi-check-circle-outline</v-icon>
            <v-icon color="red" v-else>mdi-close</v-icon>
          </template>
          <!-- <v-icon color="red" v-else>mdi-close</v-icon> -->
        </v-text-field>
        <v-checkbox
          v-model="agreement"
          :rules="[rules.required]"
          class="field terms ma-0 my-2"
          value
        >
          <template v-slot:label>
            <div class="ma-0 form__question">
              ¿Aceptas los
              <a
                target="_blank"
                href="/terminosycondiciones"
                @click.stop
              >
                Términos y Condiciones
              </a>
              ?
            </div>
          </template>
        </v-checkbox>
        <p class="form__question mb-5">Adjunta tu voucher</p>
        <the-upload-files
          :files="vouchers"
          typeFor="vouchers"
          @removeImg="removeNewVoucher"
        />
        <input
          type="file"
          multiple
          data-test-id="voucher-photo"
          accept=".png, .jpeg, .jpg, .webp"
          class="hide-input-file"
          @change="onFileChangeVoucher"
          id="vouchers"
          name="files"
          ref="vouchers"
        >
        <p v-if="limit && vouchers.length === 0" class="mt-1 ml-2" style="font-size: 11px; color: red;">
          Este campo es necesario
        </p>
        <base-button
          :width="$vuetify.breakpoint.smAndUp ? '59%': '100%'"
          type="submit"
          :loading="loading2"
          class="form__button my-10 mx-auto"
        >
          Enviar
        </base-button>
        <modal-success
          :width="$vuetify.breakpoint.smAndUp ? '60%' : '100%'"
          v-model="showMessage"
        />
      </v-form>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton'
import ModalSuccess from '@/components/ModalSuccess'
// import SectionOption from '@/components/SectionOption.vue'
import TheUploadFiles from '@/components/TheUploadFiles.vue'
import { mapActions, mapState } from 'vuex'
import { email, equalLength, min, maxLength, required, cellphone, dniCarnet } from '@/utils/rules'
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import SectionCategoryList from '@/components/SectionCategoryList'
import SectionList from '@/components/SectionList'

export default {
  name: 'RegisterForm',
  components: {
    BaseButton,
    ModalSuccess,
    // SectionOption,
    TheUploadFiles,
    SectionCategoryList,
    SectionList
  },
  data () {
    return {
      registerButton: true,
      menu: false,
      loading1: false,
      loading2: false,
      agreement: false,
      rules: {
        required,
        equalLength,
        email,
        min,
        maxLength,
        cellphone,
        dniCarnet
      },
      register: {
        email: '',
        dni: '',
        nombres: '',
        apellidos: '',
        fechaNacimiento: '',
        region: null,
        celular: '',
        universidad: '',
        gradosFormacion: '',
        carnet: '',
        pago: [],
        autorizacionFoto: null,
        medio: [],
        otroMedio: '',
        promoCode: null,
        institucionId: null
      },
      otroMedioBool: false,
      description: 'Con tu inscripción aceptas que todo material, videos, exámenes realizados por nuestros colaboradores y docentes son única y exclusivamente propiedad de USAMEDIC.',
      // activeItem: -1,
      continue: true,
      showMessage: false,
      continueRegister: false,
      vouchers: [],
      dniFoto: [],
      limit: false,
      codeValid: false,
      evaluate: false,
      currentCode: {},
      grados: [],
      regions: [],
      departaments: [],
      instituciones: []
    }
  },
  computed: {
    ...mapState(['sections', 'payment', 'references']),
    sectionCategorySelected: {
      get () {
        return this.$store.state.sectionCategorySelected
      },
      set (value) {
        this.$store.commit('SET_SECTION_CATEGORY_SELECTED', value)
      }
    },
    sectionSelected: {
      get () {
        return this.$store.state.sectionSelected
      },
      set (value) {
        this.$store.commit('SET_SECTION_SELECTED', value)
      }
    }
  },
  methods: {
    ...mapActions(['fetchReferences', 'sendRegister']),
    isFileImage (file) {
      const acceptedImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
      return file && acceptedImageTypes.includes(file.type.toLowerCase())
    },
    async fetchCities () {
      try {
        const citiesResponse = await axios.get('api/ciudades/region/')
        this.regions = citiesResponse.data
      } catch (e) {
        this.$root.$confirm({
          title: '¡Ups!',
          message: 'Asegúrate que tiene conexión a internet.',
          acceptText: 'Aceptar'
        })
      }
    },
    async fetchGrados () {
      try {
        const gradosResponse = await axios.get('api/formulario/grados_formacion/')
        this.grados = gradosResponse.data
      } catch (e) {
        this.$root.$confirm({
          title: '¡Ups!',
          message: 'Asegúrate que tiene conexión a internet.',
          acceptText: 'Aceptar'
        })
      }
    },
    async fetchInstituciones () {
      try {
        const institucionesResponse = await axios.get('api/instituciones/')
        this.instituciones = institucionesResponse.data
      } catch (e) {
        this.$root.$confirm({
          title: '¡Ups!',
          message: 'Asegúrate que tiene conexión a internet.',
          acceptText: 'Aceptar'
        })
      }
    },
    removeNewDniPhoto (index) {
      this.dniFoto.splice(index, 1)
    },
    removeNewVoucher (index) {
      this.vouchers.splice(index, 1)
    },
    onClickOutside () {
      this.active = false
    },
    // clickHandler (idx) {
    //   this.activeItem = idx
    // },
    async onFileChangeVoucher (e) {
      const selectedFiles = this.$refs.vouchers.files
      for (let i = 0; i < selectedFiles.length; i++) {
        if (!this.isFileImage(selectedFiles[i])) {
          this.$root.$confirm({
            title: '¡Ups!',
            message: 'Asegúrate de que las imagenes cargadas tengan las siguientes extensiones .png, .jpeg, .jpg, .webp.',
            acceptText: 'Aceptar'
          })
        } else {
          if (i < 5) {
            const compressedFile = await imageCompression(selectedFiles[i], {
              maxSizeMB: 1,
              maxWidthOrHeight: 1280,
              fileType: 'JPEG',
              useWebWorker: true
            })
            const name = compressedFile.name.split('.')[0]
            const myFile = await new File([compressedFile], name + '.JPEG', { type: 'image/jpeg', lastModified: Date.now() })
            this.vouchers.push(myFile)
          }
        }
      }
    },
    async onFileChangeDni (e) {
      const selectedFiles = this.$refs.dniFoto.files
      if (!selectedFiles[0]) {
        return
      }
      for (let i = 0; i < selectedFiles.length; i++) {
        if (!this.isFileImage(selectedFiles[i])) {
          this.$root.$confirm({
            title: '¡Ups!',
            message: 'Asegúrate de que las imagenes cargadas tengan las siguientes extensiones .png, .jpeg, .jpg, .webp.',
            acceptText: 'Aceptar'
          })
        } else {
          const compressedFile = await imageCompression(selectedFiles[i], {
            maxSizeMB: 1,
            maxWidthOrHeight: 1280,
            fileType: 'JPEG',
            useWebWorker: true
          })
          const name = compressedFile.name.split('.')[0]
          const myFile = await new File([compressedFile], name + '.JPEG', { type: 'image/jpeg', lastModified: Date.now() })
          if (i < 2) {
            this.dniFoto.push(myFile)
          }
        }
      }
    },
    blobToFile (theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    ToggleRegisterContinue () {
      this.continueRegister = !this.continueRegister
      this.$refs.registerForm.validate()
      if (this.$refs.email.valid && this.$refs.dni.valid && this.$refs.nombres.valid && this.$refs.apellidos.valid && this.$refs.distrito.valid && this.$refs.fechaNacimiento && this.sectionSelected !== null && this.$refs.celular.valid) {
        this.loading1 = true
        setTimeout(() => { this.continueRegister = !this.continueRegister }, 500)
      } else {
        this.loading1 = true
        const confirm = this.$root.$confirm({
          title: '¡Ups!',
          message: 'Debe llenar correctamente los campos antes de continuar.',
          acceptText: 'Aceptar'
        })
        if (confirm) {
          this.loading1 = !this.loading1
        }
      }
    },
    paymentLabel (option) {
      return option.nombre + ' - ' + option.costo
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    resetRegisterForm () {
      this.sectionSelected = null
      this.sectionCategory = null
      this.$refs.registerForm.reset()
      this.register.medio = []
      this.register.dni = ''
      this.vouchers = []
      this.dniFoto = []
      this.otroMedioBool = false
      this.loading2 = false
      this.loading1 = false
      this.continueRegister = !this.continueRegister
    },
    async sendForm (formData) {
      try {
        await this.sendRegister(formData)
        this.showMessage = true
        this.resetRegisterForm()
      } catch (e) {
        if (e.response && e.response.status === 400) {
          await this.$root.$confirm({
            title: '¡Ups!',
            message: 'Asegúrese de llenar correctamente el formulario.',
            acceptText: 'Aceptar'
          })
        }
        if (e.response && e.response.status === 502) {
          await this.$root.$confirm({
            title: '¡Ups!',
            message: 'Parece que estas experimentando problemas de red, por favor inténtelo de nuevo.',
            acceptText: 'Aceptar'
          })
        }
        if (e.response && e.response.status !== 502 && e.response.status !== 400) {
          await this.$root.$confirm({
            title: '¡Ups!',
            message: `Ha ocurrido un error inesperado, porfavor copie el siguiente texto y envielo al staff de Usamedic: ${e.response}.`,
            acceptText: 'Aceptar'
          })
        }
      }
    },
    async doRegister () {
      this.limit = true
      if (this.$refs.registerForm.validate() && this.dniFoto.length) {
        const listOfGradosFormacion = this.register.gradosFormacion.map(item => item.id)
        const formData = new FormData()
        try {
          this.loading2 = true
          // adding vouchers uploaded
          for (let i = 0; i < this.vouchers.length; i++) {
            formData.append(`vouchers[${i}]imagen`, this.vouchers[i])
          }
          for (let i = 0; i < this.dniFoto.length; i++) {
            formData.append('foto_dni', this.dniFoto[i])
          }
          // const institucionId = this.instituciones.findIndex(item => item.nombre === this.register.universidad) + 1
          // adding general information
          formData.append('email', this.register.email)
          formData.append('documento', this.register.dni)
          formData.append('nombres', this.register.nombres)
          formData.append('apellidos', this.register.apellidos)
          formData.append('fecha_nacimiento', this.register.fechaNacimiento)
          formData.append('region', this.register.region)
          formData.append('celular', this.register.celular)
          formData.append('seccion', this.sectionSelected.id)
          // formData.append('autoriza_foto', this.register.autorizacionFoto === 'Si' ? 1 : 0)
          // formData.append('tipo_pago', this.register.pago.id)
          formData.append('institucion', this.register.institucionId)
          formData.append('carnet_cmp', this.register.carnet)
          for (let i = 0; i < listOfGradosFormacion.length; i++) {
            formData.append('grados_formacion', listOfGradosFormacion[i])
          }
          for (let i = 0; i < this.register.medio.length; i++) {
            formData.append('canales_referencia', this.references.findIndex(item => item.nombre === this.register.medio[i]) + 1)
          }
          formData.append('otro_canal_referencia', this.register.otroMedio)
          if (this.codeValid) {
            formData.append('codigo', this.currentCode.id)
            await this.sendForm(formData)
          } else if (!this.codeValid && (this.register.promoCode === '' || this.register.promoCode === null)) {
            await this.sendForm(formData)
          } else {
            await this.$root.$confirm({
              title: '¡Ups!',
              message: 'Este código es inválido.',
              acceptText: 'Aceptar'
            })
          }
          this.loading2 = false
          this.limit = false
        } catch (e) {
          /* eslint-disable */
          await this.$root.$confirm({
            title: '¡Ups!',
            message: 'Debe llenar correctamente los campos para el registro.',
            acceptText: 'Aceptar'
          })
        } finally {
          this.loading2 = false
        }
        this.loading2 = false
      } else {
        await this.$root.$confirm({
          title: '¡Ups!',
          message: 'Debe llenar correctamente los campos para el registro.',
          acceptText: 'Aceptar'
        })
      }
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
    otroMedioBool: function () {
      if (!this.otroMedioBool) {
        this.register.otroMedio = ''
      }
    },
    'register.promoCode': function () {
      axios.get(`api/matriculas/codigos_referencia/verify_reference_code/?code=${this.register.promoCode}`)
        .then(response => {
          this.codeValid = true
          this.currentCode = response.data
        })
        .catch (error => {
          this.codeValid = false
        })
    }
  },
  async mounted () {
    await this.fetchReferences()
    await this.fetchCities()
    await this.fetchGrados()
    await this.fetchInstituciones()
    if (!this.otroMedioBool) {
      this.register.otroMedio = ''
    }
    this.departaments = this.regions.filter(item => item.country.id === 10)
  },
  async created () {
    if (this.$route.query.codigo) {
      await axios.get(`api/matriculas/codigos_referencia/verify_reference_code/?code=${this.$route.query.codigo}`)
        .then(response => {
          this.codeValid = true
          this.currentCode = response.data
          this.register.promoCode = this.currentCode.codigo
          this.evaluate = true
        })
        .catch (error => {
          this.$root.$confirm({
            title: '¡Ups!',
            message: 'Este código es inválido.',
            acceptText: 'Aceptar'
          })
          this.codeValid = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: hidden !important
}
.field {
  width: 59%;
  height: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 10px;
}
.field-radio label {
  color: black;
}
.datepicker {
  width: 100%;
}
.v-radio__label {
  color: black !important;
}
.field:focus {
  outline: none !important;
  border:1px solid #0583DA;
  box-shadow: 0 0 10px #1FC1D0;
}
@media (max-width: 1260px) {
  .field {
    width: 300px;
  }
}
@media (max-width: 960px) {
  .field {
    width: 100%;
  }
}
.header {
  &__name {
    z-index: 100;
    font-weight: bold;
    font-size: 25px;
    line-height: 27px;
  }
}
.gradient-color {
  background: -webkit-linear-gradient(#1FC1D0, #0583DA);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.white-color {
  color: #FFFFFF;
}
.main {
  &__sidebar {
    // margin-top: -5%;
    // flex-basis: 40%;
  }
  &__register {
    flex-basis: 60%;
    overflow: auto !important;
    width: 100% !important;
    height: 100vh !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  &__register::-webkit-scrollbar {
    display: none !important;
  }
  &__title {
    font-weight: 800 !important;
    font-size: 35px;
    line-height: 48px;
    color: #343B4D;
  }
  &__description {
    font-size: 13px;
    line-height: 19px;
    color: #656565;
    width: 311px;
  }
}
.form {
  &__question {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #000000;
  }
  &__button {
    width: 59%;
    height: 45px;
  }
  &__first {
    border-right: 1px solid #E6E6E6;
    margin-top: 0 !important;
  }
}
.modal {
  max-width: 60% !important;
}
.another {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  width: 20em;
  font-size: 1em;
  border-bottom: 2px solid black;
}
.feedback-message {
  color: red;
  font-size: 13px;
  margin-left: 5px;
  margin-top: 3px;
}
.border-error {
  border: 1px solid red;
  border-radius: 10px !important;
  width: 371px;
}
@media (max-width: 960px) {
  .main {
    flex-direction:column;
    &__register {
      flex-basis: 100%;
      width: 90% !important;
      overflow: hidden !important;
    }
    &__description {
      width: 90%;
    }
    &__sidebar {
      width: 90% !important;
    }
  }
  .modal {
    max-width: 100% !important;
  }
  .form {
    &__button {
      width: 100%;
    }
    &__question {
      font-size: 14px;
    }
    &__middle {
      display: none !important;
    }
    &__first {
      border: none;
    }
  }
  .border-error {
    width: 100%;
  }
}
.hide-input-file {
  display: none;
}
</style>
