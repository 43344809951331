<template>
<div class="d-flex flex-wrap justify-start">
  <template v-if="!loading">
  <SectionCategoryItem v-for="sectionCategory in sectionCategories" :text="sectionCategory.nombre" :image="sectionCategory.imagen" :key="sectionCategory.slug" @click="sectionCategorySelected = sectionCategory" :selected="sectionCategorySelected !== null && sectionCategory.slug=== sectionCategorySelected.slug"/>
  </template>
  <template v-else>
      <v-col cols="12" class="d-flex">
        <v-skeleton-loader type="image" class="mx-3" height="70"  width="155"/>
        <v-skeleton-loader type="image" class="mx-3" height="70"  width="155"/>
        <v-skeleton-loader type="image" class="mx-3" height="70"  width="155"/>
        <v-skeleton-loader type="image" class="mx-3" height="70"  width="155"/>
      </v-col>
    </template>
</div>
</template>

<script>
import SectionCategoryItem from '@/components/SectionCategoryItem'
import axios from 'axios'

export default {
  name: 'SectionCategoryList',
  components: {
    SectionCategoryItem
  },
  data: () => ({
    sectionCategories: [],
    loading: false
  }),
  async mounted () {
    await this.fetchSectionCategories()
    this.setSectionCategorySelectedFromQueryParam()
  },
  computed: {
    sectionCategorySelected: {
      get () {
        return this.$store.state.sectionCategorySelected
      },
      set (value) {
        this.$store.commit('SET_SECTION_CATEGORY_SELECTED', value)
      }
    },
    sectionCategoryQueryParam: {
      get () {
        return this.$route.query.categoria || null
      },
      set (value) {
        this.$route.query.categoria = value
      }
    }
  },
  watch: {
    sectionCategorySelected (value) {
      this.replaceRouteQueryParam({ categoria: value.slug })
    }
  },
  methods: {
    async fetchSectionCategories () {
      try {
        this.loading = true
        const sectionCategoriesResponse = await axios.get('api/formulario/categorias_seccion/')
        this.sectionCategories = sectionCategoriesResponse.data
      } finally {
        this.loading = false
      }
    },
    setSectionCategorySelectedFromQueryParam () {
      const sectionCategory = this.sectionCategories.find(sectionCategory => sectionCategory.slug === this.sectionCategoryQueryParam)
      if (sectionCategory === undefined) {
        this.replaceRouteWithEmptyQueryParam()
      } else {
        this.sectionCategorySelected = sectionCategory
      }
    },
    replaceRouteQueryParam (query) {
      const currentRouteQueryIsTheSameAsQuery = JSON.stringify(query) === JSON.stringify(this.$route.query)
      if (currentRouteQueryIsTheSameAsQuery) {
        return
      }
      this.$router.replace({
        ...this.$route,
        query
      })
    },
    replaceRouteWithEmptyQueryParam () {
      this.replaceRouteQueryParam({})
    }
  }
}
</script>
