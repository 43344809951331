<template>
  <div class="d-flex flex-wrap ">
    <template v-if="!loading">

      <SectionItem
        v-for="section in sections"
        :text="section.nombre"
        :image="section.imagen"
        :key="section.id"
        @click="sectionSelected = section"
        :selected="sectionSelected !== null && section.id === sectionSelected.id"
      />
    </template>
    <template v-else>
      <v-col cols="12" class="d-flex">
        <v-skeleton-loader type="image" class="mx-3" height="170" width="150"/>
        <v-skeleton-loader type="image" class="mx-3" height="170" width="150"/>
        <v-skeleton-loader type="image" class="mx-3" height="170" width="150"/>
        <v-skeleton-loader type="image" class="mx-3" height="170" width="150"/>
      </v-col>
    </template>
  </div>

</template>

<script>
import SectionItem from '@/components/SectionItem'
import axios from 'axios'

export default {
  name: 'SectionList',
  components: {
    SectionItem
  },
  data: () => ({
    sections: [],
    loading: false
  }),
  async mounted () {
    await this.fetchSections()
  },
  computed: {
    sectionCategorySelected: {
      get () {
        return this.$store.state.sectionCategorySelected
      },
      set (value) {
        this.$store.commit('SET_SECTION_CATEGORY_SELECTED', value)
      }
    },
    sectionSelected: {
      get () {
        return this.$store.state.sectionSelected
      },
      set (value) {
        this.$store.commit('SET_SECTION_SELECTED', value)
      }
    }
  },
  watch: {
    sectionCategorySelected (value) {
      this.sectionSelected = null
      this.fetchSections()
    }
  },
  methods: {

    async fetchSections () {
      if (this.sectionCategorySelected === null) {
        return
      }
      this.loading = true
      try {
        const sectionsResponse = await axios.get(`api/formulario/categorias_seccion/${this.sectionCategorySelected.slug}/secciones/`)
        this.sections = sectionsResponse.data
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
