<template>
  <div class="section d-flex flex-column align-center justify-start"  :class="{'section--selected': selected}" v-on="$listeners">
    <v-img class=" section__image flex-grow-0" :src="image" height="55" width="55" cover/>
    <p class=" section__name flex-grow-1 d-flex align-center">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'SectionItem',
  props: {
    text: String,
    image: {
      default: null,
      type: String
    },
    selected: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped lang="scss">
.section {
  padding: 15px 16px;
  margin: 0 29px 15px 0;
  background: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 5px;
  transition: all .3s ease-in-out;
  width: 150px;

  &__image {
    border-radius: 5px;

    margin-bottom: 13px;
  }

  &__name {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.24px;
    margin: 0;

    color: #000000;
  }

  &--selected {
    background: #1FC1D0;
    border: 1px solid #D5D5D5;

    & .section__name {
      color: white !important;
    }
  }
}
</style>
