<template>
  <div
    :class="files.length ? 'upload-field': ''"
    :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;': 'width: 59%;'"
  >
    <!-- Button for displaying the upload component -->
      <v-slide-x-transition>
        <div
          v-if="files.length"
          class="d-inline-flex"
        >
          <div
            class="upload-btn d-inline-flex flex-nowrap my-5 mx-1"
            v-for="(image, index) in files"
            :key="index"
            style="position: relative;"
          >
            <v-btn
              style="position: absolute; top: 0; right: 0; z-index: 50;"
              icon
            >
              <v-icon
                size="20"
                @click="$emit('removeImg', index)"
              >
                mdi-close
              </v-icon>
            </v-btn>
            <v-img
              :src="preview(image)"
              max-width="128"
              max-height="158"
              contain
            ></v-img>
          </div>
        </div>
      </v-slide-x-transition>
      <div
        :class="files.length ? 'upload-btn my-5 mx-1 d-flex': ''"
        :style="files.length ? '': 'width: 100% !important; height: 45px; border: 1px solid #D5D5D5; border-radius: 10px;'"
        v-if="typeFor !== 'vouchers' ? files.length < 1: files.length < 5"
      >
        <label :for="typeFor" style="width: 100%; height: 100%; cursor: pointer;" class="d-flex justify-center align-center">
          <v-img
            src="@/assets/img/register/add-photo.svg"
            height="17"
            max-width="17"
          ></v-img>
        </label>
      </div>
  </div>
</template>

<script>
export default {
  name: 'TheUploadFiles',
  props: {
    files: {
      type: Array,
      default: () => []
    },
    typeFor: {
      type: String,
      default: ''
    }
  },
  methods: {
    preview (img) {
      return URL.createObjectURL(img)
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-btn {
  background: #FFFFFF !important;
  border: 1px solid #D4D4D4 !important;
  border-radius: 10px !important;
  min-width: 128px;
  height: 158px;
}
.upload-field {
  // position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 210px;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  display: inline-flex;
}
</style>
